import { configureStore } from "@reduxjs/toolkit";
import settingsReducer from "./reducers/settings";
import profileReducer from "./reducers/profile";
import podReducer from "./reducers/pod";
import noteReducer from "./reducers/note";
import notesReducer from "./reducers/notes";
import loadingReducer from "./reducers/loading";

const preloadedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : {};
const store = configureStore({
  reducer: {
    settings: settingsReducer,
    profile: profileReducer,
    pod: podReducer,
    note: noteReducer,
    notes: notesReducer,
    loading: loadingReducer,
  },
  preloadedState: preloadedState,
});

store.subscribe(() => {
  localStorage.setItem("reduxState", JSON.stringify(store.getState()));
});

export default store;
