import styles from "./NoteList.module.scss";
import Checkbox from "../../molecules/Checkbox";
import NoteListPreview from "./NoteListPreview";
import { removeNote } from "../../../solid-helper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../atoms/Button";
import DropdownButton from "../../molecules/DropdownButton";

const NoteList = ({ onPreviewClick = () => {}, disabled = false }) => {
  const notes = useSelector((state) => state.notes.value);
  const [noteCheckboxes, setNoteCheckboxes] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const onBulkDelete = async () => {
    setIsDeleting(true);
    for await (const checkbox of [...noteCheckboxes].filter(
      (c) => c.isChecked,
    )) {
      await removeNote(checkbox.note);
    }
    setIsDeleting(false);
  };

  const onCheckboxChange = (item, checked) => {
    const tmpNoteCheckboxes = [...noteCheckboxes];
    const noteCheckbox = tmpNoteCheckboxes.find(
      (c) => c.note.thing === item.note.thing,
    );
    noteCheckbox.isChecked = checked;
    setNoteCheckboxes(tmpNoteCheckboxes);
  };

  const onSelectAll = () => {
    setNoteCheckboxes(
      [...noteCheckboxes].map((c) => {
        c.isChecked = true;
        return c;
      }),
    );
  };

  const onDeselectAll = () => {
    setNoteCheckboxes(
      [...noteCheckboxes].map((c) => {
        c.isChecked = false;
        return c;
      }),
    );
  };

  useEffect(() => {
    const tmpCheckboxes = [];
    notes.forEach((note) => {
      tmpCheckboxes.push({
        note: note,
        isChecked: false,
      });
    });
    setNoteCheckboxes(tmpCheckboxes);
  }, [notes]);

  return (
    <div className={styles["container"]}>
      <div className={styles["actions-container"]}>
        <DropdownButton label="Actions" className={styles["dropdown"]}>
          <ul>
            <li>
              <Button
                type="dropdown-item"
                size="small"
                disabled={!noteCheckboxes.find((c) => !c.isChecked)}
                onClick={onSelectAll}
              >
                <i className="fa-solid fa-check-double fa-left"></i>
                Select all
              </Button>
            </li>
            <li>
              <Button
                type="dropdown-item"
                disabled={!noteCheckboxes.find((c) => c.isChecked)}
                onClick={onDeselectAll}
              >
                <i className="fa-solid fa-xmark fa-left"></i>
                Deselect all
              </Button>
            </li>
            <li>
              <Button
                type="dropdown-item"
                disabled={!noteCheckboxes.find((c) => c.isChecked)}
                onClick={onBulkDelete}
              >
                {isDeleting ? (
                  <>
                    <i className="fas fa-spinner fa-spin fa-left"></i>
                    Deleting ...
                  </>
                ) : (
                  <>
                    <i className="far fa-trash-can fa-left"></i>
                    Delete{" "}
                    {noteCheckboxes.filter((c) => c.isChecked).length > 0
                      ? noteCheckboxes.filter((c) => c.isChecked).length
                      : ""}{" "}
                    {noteCheckboxes.filter((c) => c.isChecked).length === 1
                      ? "note"
                      : "notes"}
                  </>
                )}
              </Button>
            </li>
          </ul>
        </DropdownButton>
        <ul className={styles["actions"]}>
          <li>
            <Button
              size="small"
              disabled={!noteCheckboxes.find((c) => !c.isChecked) || disabled}
              onClick={onSelectAll}
            >
              <i className="fa-solid fa-check-double fa-left"></i>
              Select all
            </Button>
          </li>
          <li>
            <Button
              size="small"
              disabled={!noteCheckboxes.find((c) => c.isChecked) || disabled}
              onClick={onDeselectAll}
            >
              <i className="fa-solid fa-xmark fa-left"></i>
              Deselect all
            </Button>
          </li>
          <li>
            <Button
              size="small"
              disabled={!noteCheckboxes.find((c) => c.isChecked) || disabled}
              onClick={onBulkDelete}
            >
              {isDeleting ? (
                <>
                  <i className="fas fa-spinner fa-spin fa-left"></i>
                  Deleting ...
                </>
              ) : (
                <>
                  <i className="far fa-trash-can fa-left"></i>
                  Delete{" "}
                  {noteCheckboxes.filter((c) => c.isChecked).length > 0
                    ? noteCheckboxes.filter((c) => c.isChecked).length
                    : ""}{" "}
                  {noteCheckboxes.filter((c) => c.isChecked).length === 1
                    ? "note"
                    : "notes"}
                </>
              )}
            </Button>
          </li>
        </ul>
      </div>
      <ul className={styles["note-list"]}>
        {notes.map((n, key) => (
          <li key={key}>
            {noteCheckboxes.find((c) => c.note.thing === n.thing) && (
              <Checkbox
                onChange={onCheckboxChange}
                item={noteCheckboxes.find((c) => c.note.thing === n.thing)}
                disabled={disabled}
              />
            )}
            <NoteListPreview
              note={n}
              onClick={() => !disabled && onPreviewClick(n)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NoteList;
