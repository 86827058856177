import styles from "./NewNotePlaceholder.module.scss";
import { setNote } from "../../../reducers/note";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../atoms/Button";

const NewNotePlaceholder = ({ disabled = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.value);

  const onPlaceholderClick = () => {
    if (disabled) {
      return;
    }

    if (!profile) {
      navigate("/login");
    } else {
      dispatch(setNote(false));
      navigate("/note");
    }
  };
  return (
    <>
      <h2>Add a new note</h2>
      <div
        className={`${styles["note-placeholder-container"]} ${
          disabled ? styles.disabled : ""
        }`}
        onClick={onPlaceholderClick}
      >
        Click here to start writing ...
      </div>
      <Button disabled={true} className={styles["dummy-save-button"]}>
        <i className="fas fa-save fa-left"></i> Save
      </Button>
    </>
  );
};

export default NewNotePlaceholder;
