import styles from "./Button.module.scss";
const Button = ({ children, ...props }) => {
  const style = props.className;
  delete props.className;
  const size = props.size;
  delete props.size;
  return (
    <button
      className={`${styles.button} ${style} ${styles[size]} ${
        styles[props.className]
      } ${styles[props.type]}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
