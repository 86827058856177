import { createSlice } from "@reduxjs/toolkit";

export const notes = createSlice({
  name: "notes",
  initialState: {
    value: [],
  },
  reducers: {
    setNotes: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setNotes } = notes.actions;

export default notes.reducer;
