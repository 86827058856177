import styles from "./Header.module.scss";
import { getUrl } from "@inrupt/solid-client";
import { VCARD } from "@inrupt/lit-generated-vocab-common";
import { MODE } from "../../constants";
import { LogoutButton, useSession } from "@inrupt/solid-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getName } from "../../solid-helper";
import { setSetting } from "../../reducers/settings";
import { setProfile } from "../../reducers/profile";
import { StyledLoginButton } from "../molecules/StyledLoginButton";
import { useEffect } from "react";
import Button from "../atoms/Button";
import { setPod } from "../../reducers/pod";
import { setNotes } from "../../reducers/notes";
import { setNote } from "../../reducers/note";

const Header = () => {
  const dispatch = useDispatch();
  const { session, logout } = useSession();
  const location = useLocation();

  const settings = useSelector((state) => state.settings.value);
  const profile = useSelector((state) => state.profile.value);
  const note = useSelector((state) => state.note.value);

  const onModeClick = () => {
    const newMode = settings[MODE] === "dark" ? "light" : "dark";
    dispatch(
      setSetting({
        name: MODE,
        value: newMode,
      }),
    );
  };

  const onLogout = async () => {
    await logout();
    dispatch(setProfile(false));
    dispatch(setPod(false));
    dispatch(setNote(false));
    dispatch(setNotes([]));
  };

  useEffect(() => {
    document.body.setAttribute("data-theme", settings[MODE]);
  }, [settings]);

  switch (location.pathname) {
    case "/note":
      return (
        <header className={`${styles["header"]} ${styles["header-note"]}`}>
          <Link to="/">
            <Button>
              <i className="fas fa-arrow-left"></i>
            </Button>
          </Link>
          <h2>{note ? "Edit" : "New"} note</h2>
          <Button onClick={onModeClick}>
            <i className="fas fa-circle-half-stroke"></i>
          </Button>
        </header>
      );
    case "/login":
      return (
        <header className={`${styles["header"]} ${styles["header-login"]}`}>
          <Link to="/" className="btn">
            <i className="fas fa-arrow-left"></i>
          </Link>
          <h2>Login</h2>
          <Button onClick={onModeClick}>
            <i className="fas fa-circle-half-stroke"></i>
          </Button>
        </header>
      );
    default:
      return (
        <header
          className={`${styles["header"]} ${
            session.info.isLoggedIn
              ? styles["logged-in"]
              : styles["not-logged-in"]
          }`}
        >
          {profile && (
            <div className={styles["profile-info"]}>
              Welcome {getName(profile) ? getName(profile) : "Anonymous"}
              {getUrl(profile, VCARD.hasPhoto) ? (
                <img
                  alt="Profile"
                  src={getUrl(profile, VCARD.hasPhoto)}
                  className={styles["profile-image"]}
                />
              ) : (
                <i className="fas fa-user"></i>
              )}
            </div>
          )}
          <Button
            onClick={onModeClick}
            title={`Change to ${!settings[MODE] ? "dark" : "light"} mode`}
          >
            <i className="fas fa-circle-half-stroke"></i>
          </Button>
          {session.info.isLoggedIn ? (
            <div className={styles["logout-button-wrapper"]}>
              <LogoutButton onLogout={onLogout}>
                <Button className={styles["button-logout"]} title="Sign out">
                  <i className="fas fa-sign-out"></i>
                </Button>
              </LogoutButton>
            </div>
          ) : (
            <StyledLoginButton to="/login" text="Login" />
          )}
        </header>
      );
  }
};

export default Header;
