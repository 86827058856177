import Button from "../atoms/Button";
import styles from "./DropdownButton.module.scss";
import { useEffect, useRef, useState } from "react";
const DropdownButton = ({ ...props }) => {
  const dropdownRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const style = props.className;
  delete props.className;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  return (
    <div
      ref={dropdownRef}
      className={`${styles["dropdown-container"]} ${style}`}
    >
      <Button
        className={styles["dropdown-button"]}
        size="small"
        onClick={toggleMenu}
      >
        {props.label}
        <i className="fa-solid fa-angle-down fa-right"></i>
      </Button>
      <div
        className={`${styles["dropdown-list"]} ${
          showMenu ? styles["show"] : ""
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default DropdownButton;
