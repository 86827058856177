import styles from "./Footer.module.scss";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <ul>
        <li>{new Date().getFullYear()}</li>
        <li>
          Made with <i className="fa fa-heart"></i> by{" "}
          <a target="_blank" href="https://whitewave.studio" rel="noreferrer">
            whitewave.studio
          </a>
        </li>
      </ul>
    </footer>
  );
}
