import styles from "./Login.module.scss";
import IdentityProviderSelector from "../organisms/IdentityProviderSelector";

const Login = () => {
  return (
    <div>
      <h1>Login with solid</h1>
      <p className={styles["intro"]}>
        This application uses Solid to manage your notes.
      </p>
      <IdentityProviderSelector />
      <p>
        Don't know what Solid is?{" "}
        <a href="https://solidproject.org/" target="_blank" rel="noreferrer">
          Learn more
        </a>{" "}
        about Solid.
      </p>
      <p>
        Don't have a pod yet?{" "}
        <a
          href="https://solidproject.org/users/get-a-pod"
          target="_blank"
          rel="noreferrer"
        >
          This
        </a>{" "}
        helps you to create a personal pod.
      </p>
    </div>
  );
};

export default Login;
