import { useSelector } from "react-redux";
import { FORMAT } from "../../../constants.js";
import styles from "./NoteSliderPreview.module.scss";
import { useState } from "react";
import { getStringNoLocale } from "@inrupt/solid-client";
import { AS, SCHEMA_INRUPT } from "@inrupt/vocab-common-rdf";
import moment from "moment";

const NoteSliderPreview = ({ noteThing, onClick }) => {
  const profile = useSelector((state) => state.profile.value);
  const [maxLength] = useState(450);

  return (
    profile && (
      <div className={styles["note-preview"]} onClick={onClick}>
        <ul className={styles["note-meta-data-listing"]}>
          <li>
            <i className="far fa-square-check fa-left"></i>0/4 completed
          </li>
          <li>
            <i className="fas fa-tag"></i>
            <span className={styles["number-of-tags"]}>3</span>
          </li>
        </ul>
        <h3>{getStringNoLocale(noteThing, SCHEMA_INRUPT.name)}</h3>
        <p>
          {getStringNoLocale(noteThing, AS.content).length > maxLength
            ? `${getStringNoLocale(noteThing, AS.content).substring(
                0,
                maxLength,
              )}...`
            : getStringNoLocale(noteThing, AS.content)}
        </p>
        <span className={styles["date"]}>
          Last edited{" "}
          {moment(getStringNoLocale(noteThing, AS.updated), FORMAT).fromNow()}.
        </span>
      </div>
    )
  );
};

export default NoteSliderPreview;
