import styles from "./Checkbox.module.scss";

const Checkbox = ({ item, label, onChange, disabled = false }) => {
  const handleOnChange = (event) => {
    onChange(item, event.target.checked);
  };

  return (
    <label className={styles.checkbox}>
      <input
        type="checkbox"
        checked={item?.isChecked}
        onChange={handleOnChange}
        disabled={disabled}
      />
      <span className={label ? styles["has-label"] : ""}></span>
      {label ? label : ""}
    </label>
  );
};

export default Checkbox;
