import { useSelector } from "react-redux";
import { FORMAT } from "../../../constants.js";
import styles from "./NoteListPreview.module.scss";
import { getStringNoLocale } from "@inrupt/solid-client";
import { AS, SCHEMA_INRUPT } from "@inrupt/vocab-common-rdf";
import moment from "moment";

const NoteListPreview = ({ note, onClick }) => {
  const profile = useSelector((state) => state.profile.value);
  const tags = 0;

  return (
    profile && (
      <div className={styles["note-preview"]} onClick={onClick}>
        <span className={styles.title}>
          {note?.thing && getStringNoLocale(note.thing, SCHEMA_INRUPT.name)}
        </span>

        <span className={styles["date"]}>
          Last edited{" "}
          {note?.thing &&
            moment(getStringNoLocale(note.thing, AS.updated), FORMAT).fromNow()}
          .
        </span>

        <span className={styles.tasks}>
          {(() => {
            switch (note?.tasks?.length) {
              case 0:
                return `Has no tasks`;
              case 1:
                return `Has ${note?.tasks?.length} task`;
              default:
                return `Has ${note?.tasks?.length} tasks`;
            }
          })()}
        </span>

        <div className={styles.tag}>
          <i className="fas fa-tag"></i>
          <span className={styles["number-of-tags"]}>{tags}</span>
        </div>
      </div>
    )
  );
};

export default NoteListPreview;
