export const MODE = "MODE";
export const IDP = "IDP";
export const DATASET_NAME = "my-notes";
//export const FORMAT = "YYYY-MM-DDThh:mm:ssZ";
export const FORMAT = "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)";
export const VIEW_NOTES = {
  SLIDER: "slider",
  BY_TAG: "by tag",
  LIST: "list",
};

export const SCHEMA = "https://schema.org/";
