import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const settings = createSlice({
  name: "settings",
  initialState: {
    value: {},
  },
  reducers: {
    setSetting: (state, action) => {
      if (_.has(action.payload, "name") && _.has(action.payload, "value")) {
        const tmpValue = { ...state.value };
        tmpValue[action.payload.name] = action.payload.value;
        state.value = tmpValue;
      }
    },
  },
});

export const { setSetting } = settings.actions;

export default settings.reducer;
