import { createSlice } from "@reduxjs/toolkit";

export const profile = createSlice({
  name: "profile",
  initialState: {
    value: false,
  },
  reducers: {
    setProfile: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setProfile } = profile.actions;

export default profile.reducer;
