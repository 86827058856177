import Slider from "react-slick";
import styles from "./NoteSlider.module.scss";
import NoteSliderPreview from "./NoteSliderPreview";
import { useSelector } from "react-redux";

const NoteSlider = ({ onPreviewClick = () => {} }) => {
  const notes = useSelector((state) => state.notes.value);
  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    //variableWidth: false,
  };
  return (
    <>
      <Slider {...sliderSettings} className={styles["slick-slider"]}>
        {notes.map((noteThing, key) => (
          <NoteSliderPreview
            key={key}
            noteThing={noteThing}
            onClick={() => onPreviewClick(noteThing)}
          />
        ))}
      </Slider>

      <p className={styles["notes-preview-text"]}>
        Swipe from the right to the left to see older notes.
      </p>
    </>
  );
};

export default NoteSlider;
