import styles from "./AboutThisApplication.module.scss";
import { StyledLoginButton } from "../molecules/StyledLoginButton";
import { useSession } from "@inrupt/solid-ui-react";

const AboutThisApplication = () => {
  const session = useSession();
  return (
    <div className={styles["about-this-application"]}>
      <h2>About this application</h2>
      <p className={styles["intro"]}>
        This application is a digital application designed to help users
        capture, organize, and manage their thoughts, ideas, and important
        information in a convenient and accessible manner. It offers a
        user-friendly interface that allows individuals to create, edit, and
        delete various types of notes and checklists. It uses{" "}
        <strong>Solid</strong> that lets people store their data securely in
        decentralized data stores or pods.
      </p>
      {!session && <StyledLoginButton to="/login" text="Connect your pod" />}
    </div>
  );
};

export default AboutThisApplication;
