import { createSlice } from "@reduxjs/toolkit";

export const note = createSlice({
  name: "note",
  initialState: {
    value: false,
  },
  reducers: {
    setNote: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setNote } = note.actions;

export default note.reducer;
