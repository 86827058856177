import styles from "./FormLabel.module.scss";
const FormLabel = ({ children, ...props }) => {
  return (
    <label className={styles["form-label"]} {...props}>
      {children}
    </label>
  );
};

export default FormLabel;
