import { useEffect, useState } from "react";
import { setSetting } from "../../reducers/settings";
import { IDP } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import styles from "./IdentityProviderSelector.module.scss";
import { LoginButton } from "@inrupt/solid-ui-react";
import Logo from "../../../img/solid-logo.svg";
import Button from "../atoms/Button";
import Input from "../atoms/Input";

const IdentityProviderSelector = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.value);

  const [selectedIDPOption, setSelectedIDPOption] = useState(
    settings[IDP] ? settings[IDP] : "https://solidcommunity.net",
  );
  const [identityProviders] = useState([
    "https://login.inrupt.com",
    "https://solidcommunity.net",
    "https://solidweb.org",
    "https://solidweb.me",
    "https://teamid.live",
    "https://solid.redpencil.io",
    "https://idp.use.id",
    "https://inrupt.net",
  ]);
  const [customIdentityProvider, setCustomIdentityProvider] = useState("");
  const [currentUrl] = useState(
    window.location.protocol + "//" + window.location.host,
  );

  const onRadioChange = (event) => {
    setSelectedIDPOption(event.target.value);
  };

  const onChangeCustomIdentityProvider = (event) => {
    setCustomIdentityProvider(event.target.value);
  };

  const onError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (identityProviders.find((idp) => idp === selectedIDPOption)) {
      dispatch(
        setSetting({
          name: IDP,
          value: selectedIDPOption,
        }),
      );
    } else {
      dispatch(
        setSetting({
          name: IDP,
          value: customIdentityProvider,
        }),
      );
    }
  }, [dispatch, selectedIDPOption, customIdentityProvider, identityProviders]);

  useEffect(() => {
    if (settings[IDP]) {
      if (identityProviders.find((idp) => idp === settings[IDP])) {
        setSelectedIDPOption(settings[IDP]);
      } else {
        setSelectedIDPOption("other");
        setCustomIdentityProvider(settings[IDP]);
      }
    }
  }, [settings, identityProviders]);

  useEffect(() => {
    if (customIdentityProvider) {
      dispatch(
        setSetting({
          name: IDP,
          value: customIdentityProvider,
        }),
      );
    }
  }, [dispatch, customIdentityProvider]);

  return (
    <div className={styles["identity-provider-wrapper"]}>
      <h3>Please select your identity provider</h3>
      <ul className={styles["identity-provider-listing"]}>
        {identityProviders.map((idp, key) => (
          <li key={key}>
            <Input
              type="radio"
              id={idp}
              name="idp"
              value={idp}
              checked={selectedIDPOption === idp}
              onChange={onRadioChange}
            />
            <label htmlFor={idp}>{idp}</label>
          </li>
        ))}
        <li>
          <Input
            type="radio"
            id="other"
            name="idp"
            value="other"
            checked={selectedIDPOption === "other"}
            onChange={onRadioChange}
          />
          <label htmlFor="other">Other</label>
          <div>
            <Input
              type="text"
              placeholder="Please insert the identity provider URL here..."
              disabled={selectedIDPOption !== "other"}
              value={customIdentityProvider}
              onChange={onChangeCustomIdentityProvider}
            />
          </div>
        </li>
      </ul>

      <LoginButton
        oidcIssuer={settings[IDP]}
        redirectUrl={currentUrl}
        onError={onError}
      >
        <Button type="solid" className={styles["login-button"]}>
          <img src={Logo} width="102" height="102" alt="Solid" />
          <span>Connect your pod</span>
        </Button>
      </LoginButton>
    </div>
  );
};

export default IdentityProviderSelector;
