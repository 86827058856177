import { forwardRef } from "react";
import styles from "./Textarea.module.scss";

const Textarea = forwardRef((props, ref) => (
  <textarea ref={ref} className={styles.textarea} {...props}>
    {props.children}
  </textarea>
));

export default Textarea;
