import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Notes from "./js/components/pages/Notes.js";
import Login from "./js/components/pages/Login";
import { useRoutes } from "react-router";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./js/store.js";
import "./scss/index.scss";
import { SessionProvider } from "@inrupt/solid-ui-react";
import Footer from "./js/components/organisms/Footer";
import Wrapper from "./js/components/organisms/Wrapper";
import Header from "./js/components/organisms/Header";
import Note from "./js/components/pages/Note";

const Views = () => {
  return useRoutes([
    {
      path: "/",
      element: <Notes />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/note",
      element: <Note />,
    },
  ]);
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SessionProvider restorePreviousSession={true}>
    <Provider store={store}>
      <HashRouter>
        <Wrapper>
          <Header />
          <Views />
          <Footer />
        </Wrapper>
      </HashRouter>
    </Provider>
  </SessionProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
