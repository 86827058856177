import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./NoteEditor.module.scss";
import { getStringNoLocale } from "@inrupt/solid-client";
import { AS, SCHEMA_INRUPT } from "@inrupt/vocab-common-rdf";
import { useSession } from "@inrupt/solid-ui-react";
import { addNote, getPod, removeNote, updateNote } from "../../../solid-helper";
import _ from "lodash";
import { StyledLoginButton } from "../../molecules/StyledLoginButton";
import { useNavigate } from "react-router";
import Button from "../../atoms/Button";
import Textarea from "../../atoms/Textarea";
import Input from "../../atoms/Input";
import FormLabel from "../../atoms/FormLabel";
import { DATASET_NAME } from "../../../constants";

const NoteEditor = () => {
  const { session } = useSession();
  const navigate = useNavigate();

  const note = useSelector((state) => state.note.value);

  const [tasks, setTasks] = useState([]);
  const [noteContent, setNoteContent] = useState();
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDataset, setNoteDataset] = useState(DATASET_NAME);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const inputTitleRef = useRef();
  const inputDatasetRef = useRef();
  const textAreaRef = useRef();

  const onSaveNote = async () => {
    if (note) {
      await updateNote(note, noteTitle, noteContent, tasks);
    } else {
      await addNote(noteTitle, noteContent, tasks, noteDataset);
    }
  };

  const onDeleteNote = async (note) => {
    await removeNote(note);
  };

  const onAddTask = () => {
    setTasks([
      ...tasks,
      {
        name: "",
        completed: false,
      },
    ]);
  };

  // const onToggleState = (index) => {
  //   const tmpTasks = [...tasks];
  //   tmpTasks[index].completed = !tmpTasks[index].completed;
  //   setTasks(tmpTasks);
  // };

  const onDeleteTask = (index) => {
    const tmpTasks = [...tasks];
    tmpTasks.splice(index, 1);
    setTasks(tmpTasks);
  };

  const onUpdateTaskContent = (index, e) => {
    const tmpTasks = JSON.parse(JSON.stringify(tasks));
    tmpTasks[index].name = e.target.value || "";
    setTasks(tmpTasks);
  };

  const onClickSaveNote = async () => {
    setIsSaving(true);
    await onSaveNote();
    setIsSaving(false);
    navigate("/");
  };

  const onClickDeleteNote = async () => {
    setIsDeleting(true);
    await onDeleteNote(note);
    setIsDeleting(false);
    navigate("/");
  };

  useEffect(() => {
    if (note && !_.isEmpty(note)) {
      setNoteTitle(getStringNoLocale(note.thing, SCHEMA_INRUPT.name));
      setNoteContent(getStringNoLocale(note.thing, AS.content));
      setNoteDataset(note.datasetUrl.replace(getPod(), ""));
      setTasks(note.tasks || []);
    } else {
      setNoteTitle("");
      setNoteContent("");
      setNoteDataset(DATASET_NAME);
      setTasks([]);
    }
  }, [note]);

  useEffect(() => {
    textAreaRef.current.focus();
  }, []);

  return (
    <div>
      <FormLabel>Title *</FormLabel>
      <Input
        ref={inputTitleRef}
        type="text"
        placeholder="Please insert the title"
        value={noteTitle}
        onChange={(e) => setNoteTitle(e.target.value)}
      />
      <FormLabel>Text *</FormLabel>
      <Textarea
        ref={textAreaRef}
        defaultValue="Click here to start writing..."
        value={noteContent}
        placeholder="Please insert the note"
        rows={10}
        onChange={(e) => setNoteContent(e.target.value)}
      ></Textarea>
      <FormLabel>Dataset URL*</FormLabel>
      <div className={styles["input-group"]}>
        <span className={styles["input-group-text"]}>{getPod()}</span>
        <Input
          ref={inputDatasetRef}
          type="text"
          placeholder="Please insert the dataset location"
          value={noteDataset}
          onChange={(e) => setNoteDataset(e.target.value)}
          disabled={note}
        />
      </div>
      {tasks && tasks.length > 0 && (
        <>
          <h3>Note tasks</h3>
          <div className={styles["task-insert-listing"]}>
            <ul>
              {tasks.map((task, key) => (
                <li key={key}>
                  <div className={styles["task-field-wrapper"]}>
                    {/*<label*/}
                    {/*  htmlFor={`task-checkbox-${key}`}*/}
                    {/*  className="checkbox"*/}
                    {/*>*/}
                    {/*  <input*/}
                    {/*    type="checkbox"*/}
                    {/*    name={`task-checkbox-${key}`}*/}
                    {/*    id={`task-checkbox-${key}`}*/}
                    {/*    oooonChange={() => onToggleState(key)}*/}
                    {/*  />*/}
                    {/*  <span></span>*/}
                    {/*</label>*/}
                    <Input
                      type="text"
                      value={task.name}
                      placeholder="Write here your task"
                      className={task.completed ? styles["task-is-done"] : ""}
                      onChange={(e) => onUpdateTaskContent(key, e)}
                    />
                  </div>
                  <Button
                    className={styles["button-remove-task"]}
                    onClick={() => {
                      onDeleteTask(key);
                    }}
                  >
                    <i className="far fa-trash-can"></i>
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      <Button className={styles["button-add-task"]} onClick={onAddTask}>
        <i className="far fa-add fa-left"></i>Add task
      </Button>
      {session.info.isLoggedIn ? (
        <ul className={styles.actions}>
          <li>
            <Button
              type="primary"
              className={styles["button-save-note"]}
              onClick={onClickSaveNote}
              disabled={noteTitle.length === 0}
            >
              {isSaving ? (
                <>
                  <i className="fas fa-spinner fa-spin fa-left"></i>
                  Saving ...
                </>
              ) : (
                <>
                  <i className="far fa-save fa-left"></i>
                  Save this note
                </>
              )}
            </Button>
          </li>
          {note && (
            <li>
              <Button onClick={onClickDeleteNote}>
                {isDeleting ? (
                  <>
                    <i className="fas fa-spinner fa-spin fa-left"></i>
                    Deleting ...
                  </>
                ) : (
                  <>
                    <i className="far fa-trash-can fa-left"></i>
                    Delete this note
                  </>
                )}
              </Button>
            </li>
          )}
        </ul>
      ) : (
        <StyledLoginButton
          to="/login"
          text="Login with Solid to manage your notes"
        />
      )}
    </div>
  );
};

export default NoteEditor;
