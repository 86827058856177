import { useDispatch, useSelector } from "react-redux";
import { VIEW_NOTES } from "../../constants.js";
import { useEffect, useState } from "react";
import styles from "./Notes.module.scss";
import { useSession } from "@inrupt/solid-ui-react";
import { findNotesInPod, fetchPod, fetchProfile } from "../../solid-helper";
import { setProfile } from "../../reducers/profile";
import { setNotes } from "../../reducers/notes";
import { setNote } from "../../reducers/note";
import { useNavigate } from "react-router";
import NoteList from "../organisms/notes/NoteList";
import AboutThisApplication from "../organisms/AboutThisApplication";
import NewNotePlaceholder from "../organisms/notes/NewNotePlaceholder";
import NoteSlider from "../organisms/notes/NoteSlider";
import Button from "../atoms/Button";
import { setLoading } from "../../reducers/loading";

const Notes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile.value);
  const notes = useSelector((state) => state.notes.value);
  const isLoading = useSelector((state) => state.loading.value);
  const [viewNotes, setViewNotes] = useState(VIEW_NOTES.LIST);

  const { session } = useSession();
  const { webId } = session.info;

  const onNotePreviewClick = (note) => {
    if (!profile) {
      navigate("/login");
    } else {
      dispatch(setNote(note));
      navigate("/note");
    }
  };

  const onReloadNotesClick = async () => {
    try {
      dispatch(setLoading(true));
      dispatch(setNotes(await findNotesInPod()));
      dispatch(setLoading(false));
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  };

  useEffect(() => {
    if (webId) {
      fetchPod(webId);
    }
  }, [webId]);

  useEffect(() => {
    if (webId) {
      fetchProfile(webId);
    }
  }, [webId]);

  useEffect(() => {
    dispatch(setNote([]));
  }, [dispatch]);

  useEffect(() => {
    if (!session?.info?.isLoggedIn && profile) {
      dispatch(setProfile(false));
    }
  }, [dispatch, session, profile]);

  return (
    <div className={styles["grid-container"]}>
      <div className={styles["title-container"]}>
        <h1>Notes</h1>

        <Button
          onClick={onReloadNotesClick}
          disabled={!session?.info?.isLoggedIn}
        >
          {isLoading ? <i className="fas fa-spinner fa-spin fa-left"></i> : ""}
          {isLoading ? "Reloading notes" : "Reload notes"}
        </Button>
      </div>

      {!profile ? (
        <>
          <p>Please login to see your notes!</p>
        </>
      ) : (
        <>
          {notes && notes.length > 0 ? (
            <>
              <p className={styles["subtitle"]}>
                You have <strong>{notes.length}</strong> notes.
                {/*, with{" "}<strong>24</strong> not completed tasks.*/}
              </p>

              <ul className={styles["category-listing"]}>
                <li
                  className={
                    viewNotes === VIEW_NOTES.SLIDER ? styles["active"] : ""
                  }
                >
                  <Button
                    type="link"
                    onClick={() => setViewNotes(VIEW_NOTES.SLIDER)}
                    disabled={true}
                  >
                    Slider
                  </Button>
                </li>
                <li
                  className={
                    viewNotes === VIEW_NOTES.BY_TAG ? styles["active"] : ""
                  }
                >
                  <Button
                    type="link"
                    onClick={() => setViewNotes(VIEW_NOTES.BY_TAG)}
                    disabled={true}
                  >
                    By tag
                  </Button>
                </li>
                <li
                  className={
                    viewNotes === VIEW_NOTES.LIST ? styles["active"] : ""
                  }
                >
                  <Button
                    type="link"
                    onClick={() => setViewNotes(VIEW_NOTES.LIST)}
                  >
                    List
                  </Button>
                </li>
              </ul>

              {(() => {
                switch (viewNotes) {
                  case VIEW_NOTES.SLIDER:
                    return <NoteSlider onPreviewClick={onNotePreviewClick} />;
                  default:
                    return (
                      <NoteList
                        onPreviewClick={onNotePreviewClick}
                        disabled={isLoading}
                      />
                    );
                }
              })()}
            </>
          ) : (
            <>
              <p className={styles["subtitle"]}>
                You have <strong>0</strong> notes.
              </p>
            </>
          )}
        </>
      )}

      <NewNotePlaceholder disabled={isLoading} />

      <AboutThisApplication />
    </div>
  );
};

export default Notes;
