import {
  buildThing,
  createSolidDataset,
  createThing,
  getSolidDataset,
  getStringNoLocale,
  getThing,
  getThingAll,
  getUrl,
  getUrlAll,
  removeThing,
  saveSolidDatasetAt,
  setStringNoLocale,
  setThing,
  asUrl,
  isContainer,
  getContainedResourceUrlAll,
} from "@inrupt/solid-client";
import { AS, RDF, SCHEMA_INRUPT } from "@inrupt/vocab-common-rdf";
import { FOAF, VCARD } from "@inrupt/lit-generated-vocab-common";
import { getDefaultSession } from "@inrupt/solid-client-authn-browser";
import { DATASET_NAME, SCHEMA } from "./constants";
import store from "./store.js";
import LinkHeader from "http-link-header";
import { setProfile } from "./reducers/profile";
import { setPod } from "./reducers/pod";
import { setNotes } from "./reducers/notes";
import { setLoading } from "./reducers/loading";

const getName = (profile) => {
  if (!profile) {
    return;
  }
  switch (true) {
    case getStringNoLocale(profile, VCARD.fn) !== null:
      return getStringNoLocale(profile, VCARD.fn);
    case getStringNoLocale(profile, FOAF.name) !== null:
      return getStringNoLocale(profile, FOAF.name);
    default:
      return "";
  }
};

async function getOrCreateDataset(datasetFolder = false) {
  if (!getPod()) {
    console.log("Missing pod!");
  }
  const datasetUrl = getSolidDataUrl(datasetFolder);
  try {
    return await getDataset(datasetUrl);
  } catch (error) {
    //console.log(error);
    if (typeof error.statusCode === "number" && error.statusCode === 404) {
      // if not found, create a new SolidDataset (i.e., the reading list)
      await saveDataset(getSolidDataUrl(datasetFolder), createSolidDataset());
      return await getDataset(datasetUrl);
    } else {
      //console.error(error.message);
      //return error;
    }
  }
}

function getPod() {
  const currentStore = store.getState();
  return currentStore?.pod?.value;
}

function getSolidDataUrl(datasetFolder = false) {
  return `${getPod()}${datasetFolder ? datasetFolder : DATASET_NAME}`;
}

function getOptions() {
  const session = getDefaultSession();
  return {
    fetch: session.fetch,
  };
}

async function getDataset(datasetUrl) {
  return await getSolidDataset(datasetUrl, getOptions());
}

// async function getDataset(datasetFolder = false) {
//   return await getSolidDataset(getSolidDataUrl(datasetFolder), getOptions());
// }

async function saveDataset(url, dataset) {
  return await saveSolidDatasetAt(url, dataset, getOptions());
}

async function fetchPod(webId) {
  const currentStore = store.getState();
  if (!currentStore?.pod?.value && webId) {
    store.dispatch(setPod(await getPodRoot(webId)));
    store.dispatch(setLoading(true));
    store.dispatch(setNotes(await findNotesInPod()));
    store.dispatch(setLoading(false));
  }
}

async function fetchProfile(webId) {
  const currentStore = store.getState();
  if (!currentStore?.profile?.value) {
    const tmpProfile = getThing(
      await getSolidDataset(webId, getOptions()),
      webId,
      getOptions(),
    );
    store.dispatch(setProfile(tmpProfile));
  }
}

async function updateNote(note, title, content, tasks = []) {
  try {
    let noteDataset = await getDataset(note.datasetUrl);
    let noteThing = getThing(noteDataset, note.thing.url);
    noteThing = setStringNoLocale(noteThing, SCHEMA_INRUPT.name, title);
    noteThing = setStringNoLocale(noteThing, AS.content, content);
    noteThing = setStringNoLocale(noteThing, AS.updated, new Date().toString());
    noteDataset = setThing(noteDataset, noteThing);
    const noteUrl = asUrl(noteThing, note.datasetUrl);

    //await saveDataset(noteUrl, noteDataset);

    // Get all tasks connected to the note
    const noteTasks = getUrlAll(noteThing, `${SCHEMA}hasPart`);

    // Tasks that have to be removed
    const toBeRemovedTasks = noteTasks.filter(
      (noteTask) =>
        !tasks.map((taskUrl) => taskUrl.datasetUrl).includes(noteTask),
    );
    for (const taskUrl of toBeRemovedTasks) {
      noteDataset = removeThing(noteDataset, taskUrl);
      noteThing = buildThing(noteThing)
        .removeUrl(`${SCHEMA}hasPart`, taskUrl)
        .build();
    }

    // Tasks that have to be added
    const toBeAddedTasks = tasks.filter(
      (t) => !noteTasks.includes(t.datasetUrl),
    );
    for (const taskData of toBeAddedTasks) {
      let taskThing = buildThing(createThing({ name: taskData.name }))
        .addStringNoLocale(SCHEMA_INRUPT.name, taskData.name)
        .addUrl(RDF.type, `${SCHEMA}Task`)
        .build();

      noteDataset = setThing(noteDataset, taskThing);
      const taskUrl = asUrl(taskThing, noteUrl);
      noteThing = buildThing(noteThing)
        .addUrl(`${SCHEMA}hasPart`, taskUrl)
        .build();
    }

    // Tasks that have to be added
    const toBeUpdatedTasks = noteTasks.filter((noteTask) =>
      tasks.map((taskUrl) => taskUrl.datasetUrl).includes(noteTask),
    );
    for (const taskUrl of toBeUpdatedTasks) {
      const taskData = tasks.find((t) => t.datasetUrl === taskUrl);
      let taskThing = getThing(noteDataset, taskUrl);
      taskThing = setStringNoLocale(
        taskThing,
        SCHEMA_INRUPT.name,
        taskData.name,
      );
      noteDataset = setThing(noteDataset, taskThing);
    }

    // Save note with tasks
    noteDataset = setThing(noteDataset, noteThing);
    await saveDataset(noteUrl, noteDataset);

    // Reload
    const updatedTasks = [];
    const taskUrls = getUrlAll(noteThing, `${SCHEMA}hasPart`);
    for (const taskUrl of taskUrls) {
      const taskDataset = await getDataset(taskUrl);
      const task = getThing(taskDataset, taskUrl);
      updatedTasks.push({
        datasetUrl: taskUrl,
        name: getStringNoLocale(task, SCHEMA_INRUPT.name),
        thing: task,
      });
    }

    const currentStore = store.getState();
    let tmpNotes = [...currentStore?.notes?.value];
    let index = tmpNotes.findIndex((n) => n === note);
    tmpNotes[index] = {
      datasetUrl: note.datasetUrl,
      thing: noteThing,
      tasks: updatedTasks,
    };
    return store.dispatch(setNotes(tmpNotes));
  } catch (error) {
    console.log(error);
    return error;
  }
}

async function addNote(title, content, tasks = [], datasetFolder = false) {
  try {
    // Create new note
    let noteDatasetUrl = getSolidDataUrl(datasetFolder);
    let noteDataset = await getOrCreateDataset(datasetFolder);
    let noteThing = buildThing(
      createThing({ name: "Note#" + new Date().valueOf() }),
    )
      .addStringNoLocale(SCHEMA_INRUPT.name, title)
      .addUrl(RDF.type, AS.Note)
      .addStringNoLocale(AS.content, content)
      .addStringNoLocale(AS.updated, new Date().toString())
      .build();

    // Save note
    noteDataset = setThing(noteDataset, noteThing);
    const noteUrl = asUrl(noteThing, noteDatasetUrl);
    await saveDataset(noteUrl, noteDataset);

    const noteTasks = [];
    // Create tasks and link them to the note
    for (const taskData of tasks) {
      let taskThing = buildThing(createThing({ name: taskData.name }))
        .addStringNoLocale(SCHEMA_INRUPT.name, taskData.name)
        .addUrl(RDF.type, `${SCHEMA}Task`)
        .build();

      noteDataset = setThing(noteDataset, taskThing);
      const taskUrl = asUrl(taskThing, noteUrl);
      noteThing = buildThing(noteThing)
        .addUrl(`${SCHEMA}hasPart`, taskUrl)
        .build();

      noteTasks.push({
        datasetUrl: taskUrl,
        name: taskData.name,
        thing: taskThing,
      });
    }

    // Save note with tasks
    noteDataset = setThing(noteDataset, noteThing);
    await saveDataset(noteUrl, noteDataset);

    const currentStore = store.getState();
    let tmpNotes = [...currentStore?.notes?.value];
    tmpNotes.push({
      datasetUrl: noteDatasetUrl,
      thing: noteThing,
      tasks: noteTasks,
    });
    return store.dispatch(setNotes(tmpNotes));
  } catch (error) {
    console.log(error);
    return error;
  }
}

async function removeNote(note) {
  try {
    // Fetch the note
    let noteDataset = await getDataset(note.datasetUrl);
    const noteUrl = asUrl(note.thing, note.datasetUrl);
    let noteThing = getThing(noteDataset, noteUrl);

    // Get all tasks connected to the note
    const tasks = getUrlAll(noteThing, `${SCHEMA}hasPart`);

    // Remove all tasks
    for (const taskUrl of tasks) {
      noteDataset = removeThing(noteDataset, taskUrl);
      noteThing = buildThing(noteThing)
        .removeUrl(`${SCHEMA}hasPart`, taskUrl)
        .build();
    }
    noteDataset = setThing(noteDataset, noteThing);
    await saveDataset(note.datasetUrl, noteDataset);

    // Remove note
    noteDataset = removeThing(noteDataset, noteThing.url);
    await saveDataset(note.datasetUrl, noteDataset);

    // Remove dataset if it's empty?
    //await deleteDataset(note.datasetUrl);

    const currentStore = store.getState();
    let tmpNotes = [...currentStore?.notes?.value].filter(
      (n) => n.thing !== note.thing,
    );
    return store.dispatch(setNotes(tmpNotes));
  } catch (error) {
    console.log(error);
    return error;
  }
}

const filterThings = (things, type) => {
  return things.filter((thing) => {
    const types = getUrlAll(thing, RDF.type);
    return types.includes(type);
  });
};

async function fetchThings(url, things = []) {
  try {
    const dataset = await getSolidDataset(url, getOptions());

    // Get all things from the dataset
    const datasetThings = getThingAll(dataset);

    // Add filtered things to the main array
    things.push(...datasetThings);

    // Check if the resource is a container
    if (isContainer(dataset)) {
      // Get all contained resources
      const containedUrls = await getContainedResourceUrlAll(
        dataset,
        getOptions(),
      );

      // Recursively fetch each contained resource
      for (const containedUrl of containedUrls) {
        await fetchThings(containedUrl, things);
      }
    }
  } catch (e) {
    console.log(e);
  }
  return things;
}

async function findNotesInPod() {
  const allThings = await fetchThings(getPod());
  const noteThings = filterThings(allThings, AS.Note);
  const notes = [];

  for (const thing of noteThings) {
    const tasks = [];
    const thingUrls = getUrlAll(thing, `${SCHEMA}hasPart`);
    for (const thingUrl of thingUrls) {
      const thingDataset = await getDataset(thingUrl);
      const thing = getThing(thingDataset, thingUrl);

      if (thing && getUrlAll(thing, RDF.type).includes(`${SCHEMA}Task`)) {
        tasks.push({
          datasetUrl: thingUrl,
          name: getStringNoLocale(thing, SCHEMA_INRUPT.name),
          thing,
        });
      }
    }

    notes.push({
      datasetUrl: thing.url,
      thing,
      tasks,
    });
  }

  return notes;
}

const getPodRoot = async (url) => {
  // Check current resource header
  const session = getDefaultSession();
  let res = await session.fetch(url, { method: "HEAD" });

  if (!res.ok) return null; // throw new Error(`HTTP Error Response requesting ${url}: ${res.status} ${res.statusText}`);
  let linkHeaders;
  if (res.ok) linkHeaders = res.headers.get("Link");
  if (linkHeaders) {
    let headers = LinkHeader.parse(linkHeaders);
    for (let header of headers.refs) {
      if (
        header.uri === "http://www.w3.org/ns/pim/space#Storage" &&
        header.rel === "type"
      ) {
        return url.endsWith("/") ? url : url + "/";
      }
    }
  }

  // Check current resource for link
  try {
    let ds = await getSolidDataset(url);
    let thing = ds && getThing(ds, url);
    let storageUrl =
      thing && getUrl(thing, "http://www.w3.org/ns/pim/space#storage");
    if (storageUrl) return storageUrl;
  } catch (_ignored) {}

  let splitUrl = url.split("/");
  let index = url.endsWith("/") ? splitUrl.length - 2 : splitUrl.length - 1;
  let nextUrl = splitUrl.slice(0, index).join("/") + "/";

  return getPodRoot(nextUrl);
};

export {
  getName,
  getOrCreateDataset,
  findNotesInPod,
  updateNote,
  addNote,
  removeNote,
  fetchProfile,
  fetchPod,
  getSolidDataUrl,
  getPod,
};
