import { createSlice } from "@reduxjs/toolkit";

export const pod = createSlice({
  name: "pod",
  initialState: {
    value: false,
  },
  reducers: {
    setPod: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setPod } = pod.actions;

export default pod.reducer;
