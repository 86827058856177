import { Link } from "react-router-dom";
import styles from "./StyledLoginButton.module.scss";
import Logo from "../../../img/solid-logo.svg";
import Button from "../atoms/Button";

export function StyledLoginButton({ to = "/login", text = "" }) {
  return (
    <Link to={to} className={`btn solid-button ${styles["login-button"]}`}>
      <Button type="solid">
        <img src={Logo} width="102" height="102" alt="Solid" />
        <span>{text}</span>
      </Button>
    </Link>
  );
}
